$separator-color-light: #f3f3f3;
$separator-color: #d7d7d7;
$background-color: #f8f8f8;
$foreground-color : white;
$input-background: white;

$dark-btn-background: #131113;
$light-btn-background: #ececec;

$button-text-color: #fff;

$theme-color-1: #6fb327;
$theme-color-2: #51c878;
$theme-color-3: #aaba9f;
$theme-color-4: #849b65;
$theme-color-5: #3db264;
$theme-color-6: #9ecd7e;

$primary-color: #3a3a3a;
$secondary-color: #8f8f8f;
$muted-color: #909090;

$gradient-color-1 : #8ebb4e;
$gradient-color-2 : #6c9e37;
$gradient-color-3 : #76a543;

$lp-bg-color-1 : #6fb327;
$lp-bg-color-2 : #aaba9f;
$lp-bg-color-3 : #51c878;
$lp-bg-color-4 : #9ecd7e;

$shadowOffsetsTop : 1 3 10 14 19;
$shadowBlursTop: 1.5 5 10 14 19;
$shadowOpacitiesTop: 0.04 0.1 0.19 0.25 0.3;

$shadowOffsetsBottom : 1 3 6 10 15;
$shadowBlursBottom: 3 6 6 5 6;
$shadowOpacitiesBottom: 0.04 0.1 0.2 0.22 0.22;

$logoPath: "../../../assets/img/honda_black.svg";
$logoPathMobile: "../../../assets/img/honda_black.svg";

$lpLogoPath: "../../../assets/img/honda_black.svg";
$lpLogoPathPinned: "../../../assets/img/honda_black.svg";

@import "../_mixins.scss";
@import "../_vien.style.scss";
